import { CategoryNameEnum, CategoryType } from '../../pages/Insights/category-atributes'
import { CategoryPageImage, IBackgroundProperties, PillowPageImage } from './styles'

export const CategoryMainImage = (props: CategoryType) => {

  const backgroundProperties: ({ [key: string]: IBackgroundProperties }) = {
    [CategoryNameEnum.INVITED_AUTHORS]: {
      positionG: { x: 70, y: 100 },
      positionM: { x: 85, y: 100 }
    },
    [CategoryNameEnum.TESTS_AND_QUIZES]: {
      positionG: { x: 70, y: 100 },
      positionM: { x: 85, y: 100 }
    },
    [CategoryNameEnum.SLEEP_AND_CIENCE]: {
      positionG: { x: 100, y: 100 },
      positionM: { x: 80, y: 100 }
    },
    [CategoryNameEnum.SLEEP_AND_PERFORMANCE]: {
      positionG: { x: 100, y: 100 },
      positionM: { x: 85, y: 100 }
    },
    [CategoryNameEnum.SLEEP_AND_HEALTH]: {
      positionG: { x: 70, y: 100 },
      positionM: { x: 80, y: 100 }
    },
    [CategoryNameEnum.SLEEP_AND_CULTURE]: {
      positionG: { x: 110, y: 100 },
      positionM: { x: 90, y: 100 }
    },
    [CategoryNameEnum.SLEEP_AND_AGE]: {
      positionG: { x: 83, y: 100 },
      positionM: { x: 77, y: 100 }
    },
    [CategoryNameEnum.SLEEP_DISORDER]: {
      positionG: { x: 70, y: 100 },
      positionM: { x: 79, y: 100 }
    },
    [CategoryNameEnum.SLEEP_AND_TECHNOLOGY]: {
      positionG: { x: 100, y: 100 },
      positionM: { x: 77, y: 100 }
    },
    [CategoryNameEnum.HEALTH_AND_WELFARE]: {
      positionG: { x: 70, y: 100 },
      positionM: { x: 80, y: 100 }
    }
  }

  const values = backgroundProperties[props.name]

  const handleImage = (image: string): string[] => {
    if (!image.includes('|')) {
      return [image, image]
    }
    return image.split('|')
  }

  return (
    <>
      <CategoryPageImage
        backgroundImage={handleImage(props.image)[0]}
        backgroundImageDesktop={handleImage(props.image)[1]}
        backgroundPositionMobile={values.positionM}
        backgroundPositionDesktop={values.positionG}
        backgroundSizeMobile={values.sizeM ?? { x: 1100, y: 470 }}
        backgroundSizeDesktop={values.sizeG ?? { x: 1200, y: 410 }}
        role="img"
        aria-label={props.name}
      />
    </>
  )
}